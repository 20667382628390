import { useCallback, useEffect, useRef, useState } from 'react'

import Image from 'next/image'
import Script from 'next/script'

import {
  TypeUserState,
  dataLayerEventProductImpressions,
  dataLayerEventPromotionClick,
  dataLayerEventPromotionView,
  sendBeaconData,
  useAddresses,
  useUser
} from '@k_frontend/core'
import { Col, Container, Row } from '@k_frontend/ui'
import { useCatalog } from 'hooks/useCatalog'
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect'
import useIsVisible from 'hooks/useIsVisible'
import { IMAGE_BLUR_URL, KABUM_URL } from 'settings/kernel'
import { ListingTypes, type Product } from 'types/catalog'
import { getBreadcrumbSchema } from 'utils/schema'

import { ResizeHOC } from 'components/ResizeHOC'
import { tailwindTheme } from 'theme/tailwindTheme'
import { combineSponsoredCatalogProducts } from 'utils/combineSponsoredCatalogProducts'
import { formatSendBeaconData } from 'utils/formatSendBeaconData'
import { shallow } from 'zustand/shallow'
import MainContentPageCatalog from './MainContentPageCatalog'
import { BannerImageLoading } from './PageCatalog.loading'
import * as S from './PageCatalog.styles'
import type { CatalogViewProps } from './PageCatalog.types'

const useUserSelector = (state: TypeUserState) => ({
  isLoggedIn: state.isLoggedIn,
  clientId: state.clientId,
  session: state.session
})

export default function PageCatalog({
  type,
  categories,
  onProductClick,
  banners,
  bannersAds,
  isLoadingBanner,
  sponsoredProducts,
  loadingSponsoredProducts,
  catalogServer,
  productPathLink,
  breadcrumbServer,
  pagelist,
  titleHeading,
  cookieIsMobile
}: CatalogViewProps) {
  const { catalog, params, loading } = useCatalog()
  const { query } = params
  const { breadcrumb } = catalogServer?.meta || {}
  const bannerRef = useRef()
  const [onScreenMainBanner, setOnScreenMainBanner] = useState(false)
  const [wasPrintedMainBanner, setWasPrintedMainBanner] = useState(false)
  const [imageLoading, setImageLoading] = useState(true)

  const { isLoggedIn, clientId, session } = useUser(useUserSelector, shallow)
  const addresses = useAddresses((state) => state.addresses)

  const beaconData = formatSendBeaconData(clientId, session)

  const mainBannerObserver = useIsVisible(setOnScreenMainBanner, {
    threshold: 0.75
  })

  if (bannerRef.current && !wasPrintedMainBanner) {
    mainBannerObserver?.observe(bannerRef.current)
  }

  const bannerFallback = banners?.main

  const payloadFormatted = [
    {
      name: bannersAds?.campaignName || bannerFallback?.titulo,
      index: 1,
      position: 'tema_listagem_1',
      id: bannersAds?.campaignName || bannerFallback?.banner_id || '',
      creative: bannersAds?.campaignName || bannerFallback?.creative || ''
    }
  ]

  const loadingProducts = loading || loadingSponsoredProducts

  const combinedCatalogServerProducts = combineSponsoredCatalogProducts(
    sponsoredProducts,
    catalogServer
  )

  const combinedCatalogProducts =
    catalog && combineSponsoredCatalogProducts(sponsoredProducts, catalog)

  const catalogProducts =
    combinedCatalogProducts || combinedCatalogServerProducts

  const dataLayerBannerAdsClick = () => {
    dataLayerEventPromotionClick(payloadFormatted)
    if (bannersAds?.clickUrl) {
      sendBeaconData(bannersAds?.clickUrl, beaconData)
    }
  }

  useEffect(() => {
    if (onScreenMainBanner && !wasPrintedMainBanner) {
      sendBeaconData(bannersAds?.viewUrl, beaconData)
      setWasPrintedMainBanner(true)
    }
  }, [onScreenMainBanner, wasPrintedMainBanner])

  useEffect(() => {
    if ((bannersAds || bannerFallback) && !isLoadingBanner) {
      dataLayerEventPromotionView(payloadFormatted)
      sendBeaconData(bannersAds?.impressionUrl, beaconData)
    }
  }, [isLoadingBanner])

  useDidUpdateEffect(() => {
    if (!loadingProducts) {
      dataLayerEventProductImpressions({
        products: mapProducts(catalogProducts.data)
      })

      if (sponsoredProducts?.data.length) {
        sponsoredProducts.data.map((SponsoredProduct) =>
          sendBeaconData(SponsoredProduct.newtail.impressionUrl, beaconData)
        )
      }
    }
  }, [loadingProducts])

  function mapProducts(products: Product[]) {
    return products.map((product, index) => ({
      name: product.name,
      id: product.code.toString(),
      price: Number(product?.price?.toFixed(2)) || 0,
      brand: product?.manufacturer?.name || '',
      category: product.category,
      list: pagelist,
      position: index + 1,
      dimension20: product.sellerId,
      dimension21: product.sellerName
    }))
  }

  function renderBreadcrumb() {
    const getHref = (breadcrumb, index) => {
      const sliced = breadcrumb.slice(0, index + 1)
      const href = sliced.map((crumb) => crumb.path).join('')
      return `${KABUM_URL}${href}`
    }

    const getSchema = (breadcrumb) => {
      const breadcrumbSchema = breadcrumb?.map((item) => ({
        name: item?.name,
        item: item?.path ? `${item?.path}` : null
      }))

      return (
        <Script
          id='breadcrumbSchemaListing'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: getBreadcrumbSchema(breadcrumbSchema)
          }}
        />
      )
    }

    const isRedirectedSearch = !!breadcrumb?.length

    if (type === ListingTypes.SEARCH && !isRedirectedSearch) {
      return (
        <>
          {getSchema([{ name: `Você pesquisou por: ${query}` }])}
          <b>Você pesquisou por:&nbsp;</b>
          <h1>{query}</h1>
        </>
      )
    }

    if (
      type === ListingTypes.LISTING ||
      type === ListingTypes.JUST_ARRIVED ||
      type === ListingTypes.MOST_SEARCHED ||
      type === ListingTypes.PROMOTION ||
      type === ListingTypes.RELEASES ||
      type === ListingTypes.HIGHLIGHTS ||
      type === ListingTypes.SHOWCASE ||
      type === ListingTypes.BRAND_SHOWCASE ||
      (type === ListingTypes.SEARCH && isRedirectedSearch)
    ) {
      const breadcrumbElement = []
      const breadcrumbSchema = []

      if (breadcrumb?.length) {
        breadcrumb.forEach((item, index) => {
          breadcrumbSchema.push({ ...item, path: getHref(breadcrumb, index) })
          breadcrumbElement.push(
            <a key={`a-${index}`} href={`${getHref(breadcrumb, index)}`}>
              {item.name}
            </a>
          )
          breadcrumbElement.push(<span key={`span-${index}`}>{` > `}</span>)
        })
      } else if (breadcrumbServer?.length) {
        breadcrumbServer.forEach((item, index) => {
          breadcrumbSchema.push({
            ...item,
            path: getHref(breadcrumbServer, index)
          })
          breadcrumbElement.push(
            <a key={`a-${index}`} href={`${getHref(breadcrumbServer, index)}`}>
              {item.name}
            </a>
          )
          breadcrumbElement.push(<span key={`span-${index}`}>{` > `}</span>)
        })
      }

      breadcrumbElement.pop()

      if (!breadcrumbElement?.length) {
        return null
      }

      return (
        <>
          {getSchema(breadcrumbSchema)}
          <b>Você está em:&nbsp;</b>
          <div>{breadcrumbElement}</div>
        </>
      )
    }
  }

  const renderMainBanner = useCallback(() => {
    const bannerImage = bannersAds?.mediaUrl
    const hasMainBanner =
      !!banners?.main?.banner || !!banners?.main?.banner_mobile
    const bannerRedirect = bannersAds?.destinationUrl
    const isRedirectedSearch = !!breadcrumb?.length
    const isBannerImageLoading = imageLoading || isLoadingBanner
    const hasNoBanner = isBannerImageLoading || !bannerImage
    const defaultBannerAlt = 'Banner do topo da listagem'

    if (cookieIsMobile && !banners?.main?.banner_mobile && !bannerImage) {
      return (
        <S.BannerListagem
          id='listingHeader'
          onClick={() => dataLayerBannerAdsClick()}
        >
          <S.SlugHighlight id='headerName'>
            <h1>
              {titleHeading ||
                breadcrumb?.[breadcrumb?.length - 1]?.name ||
                breadcrumbServer?.[breadcrumbServer?.length - 1]?.name ||
                query?.replace(/-/g, ' ') ||
                banners?.main?.titulo}
            </h1>
          </S.SlugHighlight>
        </S.BannerListagem>
      )
    }

    if (
      !Object?.keys(banners?.main || {})?.length &&
      !!breadcrumbServer?.length
    ) {
      return (
        <S.BannerListagem id='listingHeader'>
          <S.SlugHighlight id='headerName'>
            <h1>
              {titleHeading ||
                breadcrumbServer?.[breadcrumbServer?.length - 1]?.name}
            </h1>
          </S.SlugHighlight>
        </S.BannerListagem>
      )
    }

    if (type === ListingTypes.LISTING) {
      return (
        <S.BannerListagem
          heightMobile='166px'
          heightTablet='166px'
          heightDesktop='316px'
          id='listingHeader'
          onClick={() => dataLayerBannerAdsClick()}
        >
          <S.WrapperImage hasNoBanner={hasNoBanner}>
            {isBannerImageLoading && <BannerImageLoading />}
            <a href={bannerRedirect} rel='noreferrer'>
              <Image
                src={bannerImage || IMAGE_BLUR_URL}
                width={cookieIsMobile ? 599 : 1216}
                height={cookieIsMobile ? 275 : 310}
                alt={bannersAds?.alt || defaultBannerAlt}
                onLoad={() => setImageLoading(false)}
                priority
              />
            </a>
          </S.WrapperImage>
          <S.SlugHighlight id='headerName'>
            <h1>
              {titleHeading ||
                breadcrumb?.[breadcrumb?.length - 1]?.name ||
                breadcrumbServer?.[breadcrumbServer?.length - 1]?.name ||
                query?.replace(/-/g, ' ')}
            </h1>
          </S.SlugHighlight>
        </S.BannerListagem>
      )
    } else if (
      type === ListingTypes.JUST_ARRIVED ||
      type === ListingTypes.MOST_SEARCHED ||
      type === ListingTypes.PROMOTION ||
      type === ListingTypes.RELEASES ||
      type === ListingTypes.HIGHLIGHTS ||
      type === ListingTypes.SHOWCASE ||
      type === ListingTypes.BRAND_SHOWCASE ||
      (type === ListingTypes.SEARCH && isRedirectedSearch)
    ) {
      return (
        <S.BannerListagem
          heightMobile='166px'
          heightTablet='166px'
          heightDesktop='316px'
          id='listingHeader'
          onClick={() => dataLayerBannerAdsClick()}
        >
          <S.WrapperImage hasNoBanner={imageLoading || !hasMainBanner}>
            {isBannerImageLoading && <BannerImageLoading />}
            <a
              href={banners?.main?.link}
              rel='noreferrer'
              target={banners?.main?.target_blank ? '_blank' : '_self'}
            >
              <Image
                src={
                  (cookieIsMobile
                    ? banners?.main?.banner_mobile
                    : banners?.main?.banner) || IMAGE_BLUR_URL
                }
                width={cookieIsMobile ? 599 : 1216}
                height={cookieIsMobile ? 275 : 310}
                alt={banners?.main?.titulo || defaultBannerAlt}
                onLoad={() => setImageLoading(false)}
                priority
              />
            </a>
          </S.WrapperImage>
          <S.SlugHighlight id='headerName'>
            <h1>
              {titleHeading ||
                breadcrumb?.[breadcrumb?.length - 1]?.name ||
                breadcrumbServer?.[breadcrumbServer?.length - 1]?.name ||
                query?.replace(/-/g, ' ') ||
                banners?.main?.titulo}
            </h1>
          </S.SlugHighlight>
        </S.BannerListagem>
      )
    }
  }, [
    bannersAds,
    banners,
    isLoadingBanner,
    imageLoading,
    breadcrumb,
    titleHeading,
    params
  ])

  function renderCatalog(catalogComponent) {
    return (
      <div id='listing'>
        <Container>
          <Row>
            <Col>
              <S.CatalogQueryInfo id='listingBreadcrumbs'>
                <ResizeHOC
                  minDesktopWidth={tailwindTheme.screens.tablet}
                  MobileComponent={() => null}
                  DesktopComponent={() => renderBreadcrumb()}
                  isMobileScreen={cookieIsMobile}
                />
              </S.CatalogQueryInfo>
            </Col>
          </Row>
        </Container>
        <Container
          sm={{ overflowLeft: true, overflowRight: true }}
          md={{ overflowLeft: true, overflowRight: true }}
        >
          <Row>
            <Col justify='space-between'>
              <S.ContainerMainBanner ref={bannerRef}>
                <ResizeHOC
                  minDesktopWidth={tailwindTheme.screens.tablet}
                  MobileComponent={() => renderMainBanner()}
                  DesktopComponent={() => renderMainBanner()}
                  isMobileScreen={cookieIsMobile}
                />
              </S.ContainerMainBanner>
            </Col>
          </Row>
        </Container>
        <S.ContainerCustom>
          <MainContentPageCatalog
            dataCatalog={catalogComponent}
            loadingProducts={loadingProducts}
            isLoggedIn={isLoggedIn}
            addresses={addresses}
            categories={categories}
            onProductClick={onProductClick}
            productPathLink={productPathLink}
            renderBreadcrumb={renderBreadcrumb}
            banners={banners}
            breadcrumb={breadcrumb}
            cookieIsMobile={cookieIsMobile}
            catalogServer={catalogServer}
          />
        </S.ContainerCustom>
      </div>
    )
  }

  if (!combinedCatalogServerProducts && !loadingProducts) {
    return <noscript>{renderCatalog(combinedCatalogServerProducts)}</noscript>
  }

  return <>{renderCatalog(catalogProducts)}</>
}
